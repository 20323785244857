import { Components } from '@mui/material';

//todo utilize MuiTableRow-root MuiTableCell-root
const MuiTableHead: Components['MuiTableHead'] = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      '& .MuiTableCell-root': {
        padding: '16px 20px',

        '&.no-pt-hcell': {
          paddingTop: '0'
        },

        '&.sx': {
          paddingLeft: '15px',
          paddingRight: '15px'
        },

        '&.first-child': {
          paddingLeft: '20px'
        },
        '&.last-child': {
          paddingRight: '20px'
        }
      }
    })
  }
};

export default MuiTableHead;
