import './auth.css';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ReactComponent as Email } from 'assets/svg/email-icon.svg';
import { authAPI } from 'services/authAPI';
import { Button, filledInputClasses, Stack, TextField, useTheme } from '@mui/material';
import schema from './schema/ResetPasswordSchema';

export const ResetPasswordForm = () => {
  const { palette } = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const [authError, setAuthError] = useState<string | null>(null);
  const [resetStatus, setResetStatus] = useState(false);

  const onSubmit = async (data: any) => {
    const { email } = data;
    try {
      await authAPI.passwordResetRequest({ email });
      setResetStatus(true);
    } catch (error: any) {
      setAuthError(error?.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <form className='auth-form' onSubmit={handleSubmit(onSubmit)}>
      <h3 className='auth-title'>Reset password</h3>
      {resetStatus ? (
        <>
          <span className='reset-info'>
            If a user with this email exists in our system, we will send a link to reset your password.
          </span>
          <button type='submit'>send email again</button>
        </>
      ) : (
        <>
          <Stack
            sx={{
              px: 1.5,
              py: 0.2,
              mb: '10px',
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 3.2,
              border: `1px solid ${palette.border.o40}`
            }}
          >
            <Email />

            <TextField
              {...register('email')}
              type='email'
              label='Email'
              sx={{
                width: 1,
                [`& .${filledInputClasses.root}`]: {
                  mt: 0,
                  ':hover': {
                    backgroundColor: palette.background.paper
                  },
                  border: 0
                }
              }}
            />
          </Stack>

          {errors.email && <span className='error-text'>{errors.email.message}</span>}
          {authError && <span className='error-text'>{authError || 'Something went wrong'}</span>}
          <Button type='submit' onClick={handleSubmit(onSubmit)}>
            reset password
          </Button>
        </>
      )}
    </form>
  );
};
